import { Alert, Box, Button, Snackbar, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { format } from "date-fns";
import numeral from "numeral";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import useAuth from "../../app/hooks/useAuth";
import {
  addLiquidityLimitUser,
  verifyUser,
} from "../../app/services/AdminService";
import {
  addCredit,
  addDebit,
  addP2pDeposit,
} from "../../app/services/BankService";
import {
  activeteUser,
  addAffiliate,
  getUserAccessToken,
  removeAffiliate,
  update2faToUser,
} from "../../app/services/UserService";
import { notifySuccess } from "../../utils/toast";
import Chip from "../Chip";
import ModalAddAffiliate from "../Modals/ModalAddAffiliate";
import ModalAddCreditDebit from "../Modals/ModalAddCreditDebit";
import ModalAdminChoice from "../Modals/ModalAdminChoice";
import ModalManualActivateUser from "../Modals/ModalManualActivateUser";
import ModalUpdateLiquidityUser from "../Modals/ModalUpdateLiquidityUser";
import TableMetadata from "../Tables/TableMetadata";


const ebinexHost = window.location.host.includes("localhost")
  ? `http://localhost:3001`
  : window.location.host.includes("testnet")
  ? `https://testnet.ebinex.com`
  : `https://ebinex.com`;

const ebinexHostAdminNormal = window.location.host.includes("localhost")
  ? `http://localhost:3000`
  : window.location.host.includes("testnet")
  ? `https://admin-testnet.ebinex.com`
  : `https://grkadmin.ebinex.com`;

const ebinexHostPartnerNew = window.location.host.includes("localhost")
  ? `http://localhost:3001`
  : window.location.host.includes("testnet")
  ? `https://partner-testnet.ebinex.com`
  : `https://partner.ebinex.com`;

const InitSnackbar = {
  open: false,
  severity: "success",
  text: "",
};

export default function StatiscticsUser({
  accountId,
  userStatistics,
  onRefresh,
  ReportView = false,
}) {
  const [showAddCreditDebit, setShowAddCreditDebit] = useState(false);
  const [showVerifyUser, setShowVerifyUser] = useState(false);
  const [showActivateUser, setShowActivateUser] = useState(false);
  const [showLimitUser, setShowLimitUser] = useState(false);
  const [showAddAffiliate, setShowAddAffiliate] = useState(false);

  const [openSnackbar, setOpenSnackbar] = React.useState(InitSnackbar);
  const { user } = useAuth();

  const toggleCloseModalAddCreditDebit = () => {
    setShowAddCreditDebit(false);
  };

  const toggleCloseModalAddAffiliate = () => {
    setShowAddAffiliate(false);
  };

  const handleAddCreditDebit = async ({ value, message, selectedOption }) => {
    try {
      switch (selectedOption) {
        case "credit":
          await addCredit({ accountId, value, asset: "USDT", message });
          setOpenSnackbar({
            open: true,
            severity: "success",
            text: "Credito adicionado com sucesso.",
          });
          toggleCloseModalAddCreditDebit();
          break;
        case "debit":
          await addDebit({ accountId, value, asset: "USDT", message });
          setOpenSnackbar({
            open: true,
            severity: "success",
            text: "Débito adicionado com sucesso.",
          });
          toggleCloseModalAddCreditDebit();
          break;
        case "deposit_p2p":
          await addP2pDeposit({ accountId, value, asset: "USDT", message });
          setOpenSnackbar({
            open: true,
            severity: "success",
            text: "Depósito P2P adicionado com sucesso.",
          });
          toggleCloseModalAddCreditDebit();
          break;
        default:
          break;
      }

      onRefresh();
    } catch (error) {
      setOpenSnackbar({
        open: true,
        severity: "error",
        text: "Falha ao adicionar",
      });
    }
  };

  const handleVarifyUser = async () => {
    try {
      await verifyUser(userStatistics?.userData?.id);
      onRefresh();
    } catch (error) {
      setOpenSnackbar({
        open: true,
        severity: "error",
        text: "Falha ao verificar usuário",
      });
    }
    setShowVerifyUser(false);
  };

  const handleUpdateLiquidityUser = async (newValue) => {
    try {
      await addLiquidityLimitUser({
        email: userStatistics.email,
        liquidityLimit: newValue,
      });
      setShowLimitUser(false);
      notifySuccess(
        `Limite de $${newValue} adicionado ao usuário: ${userStatistics.email}`
      );
    } catch (error) {
      setShowLimitUser(false);
    }
  };

  const handleActivateUser = async () => {
    try {
      await activeteUser(userStatistics?.userData?.id);
      notifySuccess("Usuário ativado com sucesso");
      setShowActivateUser(false);
    } catch (error) {
      console.error("Failed to activate user:", error);
    } finally {
      setShowActivateUser(false);
    }
  };

  console.log("user", user);

  const handleLoginUser = async () => {
    try {
      const res = await getUserAccessToken(accountId);
      let token = res.data;
      token = token.replace("Bearer ", "");
      window.open(
        `${ebinexHost}/login?token=${token}&accountId=${accountId}`,
        "_blank"
      );
    } catch (error) {
      console.error("Failed to login user:", error);
    }
  };

  const handleLoginAdmin = async () => {
    try {
      const res = await getUserAccessToken(accountId);
      let token = res.data;
      token = token.replace("Bearer ", "");
      const urlNormal = `${ebinexHostAdminNormal}/login?token=${token}&accountId=${accountId}&admin=true`;
      const urlNew = `${ebinexHostPartnerNew}/auth/login?token=${token}&accountId=${accountId}&partner=true`;

      // Abrir o modal para escolher o admin
      setModalOpen(true);
      setUrls({ normal: urlNormal, new: urlNew });
    } catch (error) {
      console.error("Failed to login admin:", error);
    }
  };

  const handleAddAffiliate = async (email) => {
    try {
      await addAffiliate(accountId, email);
      notifySuccess(`Afiliação adicionada ao usuário: ${userStatistics.email}`);
    } catch (error) {
      console.error("Erro ao Adicionar afiliação:", error);
    }
  };

  const handleRemoveAffiliate = async () => {
    try {
      await removeAffiliate(accountId);
      notifySuccess(`Afiliação removida do usuário: ${userStatistics.email}`);
    } catch (error) {
      console.error("Erro ao remover afiliação:", error);
    }
  };

  const handleToggle2fa = async () => {
    try {
      const new2faState = !userStatistics.userData.using2fa;

      await update2faToUser(userStatistics?.userData?.id, new2faState);
      notifySuccess(
        `2FA ${new2faState ? "habilitado" : "desabilitado"} com sucesso!`
      );
      onRefresh();
    } catch (error) {
      console.error("Erro ao atualizar 2FA:", error);
      setOpenSnackbar({
        open: true,
        severity: "error",
        text: "Falha ao atualizar 2FA",
      });
    }
  };

  // Estado para controlar o modal e as URLs
  const [modalOpen, setModalOpen] = useState(false);
  const [urls, setUrls] = useState({
    normal: ebinexHostAdminNormal,
    new: ebinexHostPartnerNew,
  });

  const handleConfirmNormal = async () => {
    await navigator.clipboard.writeText(urls.normal);
    setModalOpen(false);
    notifySuccess(
      "URL do Admin Normal copiada para a área de transferência. Abra uma janela anônima e cole a URL."
    );
  };

  const handleConfirmNew = async () => {
    await navigator.clipboard.writeText(urls.new);
    setModalOpen(false);
    notifySuccess(
      "URL do Novo Admin copiada para a área de transferência. Abra uma janela anônima e cole a URL."
    );
  };

  return (
    <>
      <ModalAdminChoice
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirmNormal={handleConfirmNormal}
        onConfirmNew={handleConfirmNew}
      />
      <Grid container spacing={2}>
        <Grid xs={6} md={3}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h4 className="mb-0">{userStatistics?.userData?.name}</h4>
                  </div>
                </div>
                {/* <div className="col-3">
                  <div className="icon icon-box-success ">
                    <span className="mdi mdi-arrow-top-right icon-item"></span>
                  </div>
                </div> */}
              </div>
              <h6 className="text-muted font-weight-normal">Nome</h6>
            </div>
          </div>
        </Grid>
        <Grid xs={6} md={3}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h4 className="mb-0">{userStatistics?.userData?.email}</h4>
                  </div>
                </div>
                {/* <div className="col-3">
                  <div className="icon icon-box-success ">
                    <span className="mdi mdi-arrow-top-right icon-item"></span>
                  </div>
                </div> */}
              </div>
              <h6 className="text-muted font-weight-normal">Email</h6>
            </div>
          </div>
        </Grid>
        <Grid xs={6} md={3}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h4 className="mb-0">{userStatistics?.environment}</h4>
                  </div>
                </div>
                {/* <div className="col-3">
                  <div className="icon icon-box-success ">
                    <span className="mdi mdi-arrow-top-right icon-item"></span>
                  </div>
                </div> */}
              </div>
              <h6 className="text-muted font-weight-normal">Ambiente</h6>
            </div>
          </div>
        </Grid>
        <Grid xs={6} md={3}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h4 className="mb-0">
                      {userStatistics?.userData?.lastLogin &&
                        format(
                          new Date(userStatistics?.userData?.lastLogin),
                          "dd/MM/yyyy HH:mm"
                        )}
                    </h4>
                  </div>
                </div>
                {/* <div className="col-3">
                  <div className="icon icon-box-success ">
                    <span className="mdi mdi-arrow-top-right icon-item"></span>
                  </div>
                </div> */}
              </div>
              <h6 className="text-muted font-weight-normal">Último login</h6>
            </div>
          </div>
        </Grid>
        <Grid xs={6} md={3}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h4 className="mb-0">{userStatistics.operationsCount}</h4>
                  </div>
                </div>
                {/* <div className="col-3">
                  <div className="icon icon-box-success ">
                    <span className="mdi mdi-arrow-top-right icon-item"></span>
                  </div>
                </div> */}
              </div>
              <h6 className="text-muted font-weight-normal">
                Operações totais
              </h6>
            </div>
          </div>
        </Grid>
        <Grid xs={6} md={3}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h4 className="mb-0">
                      $ {numeral(userStatistics.totalAccept).format("0,0.00")}
                    </h4>
                  </div>
                </div>
                {/* <div className="col-3">
                  <div className="icon icon-box-success">
                    <span className="mdi mdi-arrow-top-right icon-item"></span>
                  </div>
                </div> */}
              </div>
              <h6 className="text-muted font-weight-normal">
                {" "}
                Volume total operado
              </h6>
            </div>
          </div>
        </Grid>
        <Grid xs={6} md={3}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h4 className="mb-0">
                      $ {numeral(userStatistics.liquidRevenue).format("0,0.00")}
                    </h4>
                  </div>
                </div>
                {/* <div className="col-3">
                  <div className="icon icon-box-danger">
                    <span className="mdi mdi-arrow-bottom-left icon-item"></span>
                  </div>
                </div> */}
              </div>
              <h6 className="text-muted font-weight-normal">Receita Líquida</h6>
            </div>
          </div>
        </Grid>
        <Grid xs={6} md={3}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h4 className="mb-0">
                      $ {numeral(userStatistics.balance).format("0,0.00")}
                    </h4>
                  </div>
                </div>
                {/* <div className="col-3">
                  <div className="icon icon-box-danger">
                    <span className="mdi mdi-arrow-bottom-left icon-item"></span>
                  </div>
                </div> */}
              </div>
              <h6 className="text-muted font-weight-normal">Saldo</h6>
            </div>
          </div>
        </Grid>
        <Grid xs={6} md={3}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h4 className="mb-0">
                      <Chip
                        className="win h4"
                        value={userStatistics.winCount}
                      />
                    </h4>
                  </div>
                </div>
                {/* <div className="col-3">
                  <div className="icon icon-box-danger">
                    <span className="mdi mdi-arrow-bottom-left icon-item"></span>
                  </div>
                </div> */}
              </div>
              <h6 className="text-muted font-weight-normal">
                Contagem vitória
              </h6>
            </div>
          </div>
        </Grid>
        <Grid xs={6} md={3}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h4 className="mb-0">
                      <Chip
                        className="lose h4"
                        value={userStatistics.loseCount}
                      />
                    </h4>
                  </div>
                </div>
                {/* <div className="col-3">
                  <div className="icon icon-box-danger">
                    <span className="mdi mdi-arrow-bottom-left icon-item"></span>
                  </div>
                </div> */}
              </div>
              <h6 className="text-muted font-weight-normal">
                Contagem de perda
              </h6>
            </div>
          </div>
        </Grid>
        <Grid xs={6} md={3}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h4 className="mb-0">
                      <Chip
                        className="win h4"
                        value={
                          "$ " +
                          numeral(userStatistics.liquidWin).format("0,0.00")
                        }
                      />
                    </h4>
                  </div>
                </div>
                {/* <div className="col-3">
                  <div className="icon icon-box-danger">
                    <span className="mdi mdi-arrow-bottom-left icon-item"></span>
                  </div>
                </div> */}
              </div>
              <h6 className="text-muted font-weight-normal">Vitória líquida</h6>
            </div>
          </div>
        </Grid>
        <Grid xs={6} md={3}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h4 className="mb-0">
                      <Chip
                        className="lose h4"
                        value={
                          "$ " +
                          numeral(userStatistics.liquidLoss).format("0,0.00")
                        }
                      />
                    </h4>
                  </div>
                </div>
                {/* <div className="col-3">
                  <div className="icon icon-box-danger">
                    <span className="mdi mdi-arrow-bottom-left icon-item"></span>
                  </div>
                </div> */}
              </div>
              <h6 className="text-muted font-weight-normal">Perda líquida</h6>
            </div>
          </div>
        </Grid>
        {!ReportView && (
          <>
            <Grid xs={6} md={3}>
              <Link
                to={`/transaction?accountId=${accountId}`}
                className="card card-button"
                style={{ textDecoration: "none" }}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-9">
                      <div className="d-flex align-items-center align-self-start">
                        <h4 className="mb-0 text-white">Transações </h4>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="icon icon-box-success">
                        <span className="mdi mdi-arrow-right icon-item"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Grid>
            <Grid xs={6} md={3}>
              <Link
                to="#"
                className="card card-button"
                onClick={() => setShowAddCreditDebit(true)}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-9">
                      <div className="d-flex align-items-center align-self-start">
                        <h4 className="mb-0 text-white">
                          Adicionar crédito/débito{" "}
                        </h4>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="icon icon-box-success">
                        <span className="mdi mdi-currency-usd icon-item"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Grid>
            {!userStatistics?.userData?.verified && (
              <Grid xs={6} md={3}>
                <Link
                  to="#"
                  className="card card-button"
                  onClick={() => setShowVerifyUser(true)}
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="col-9">
                        <div className="d-flex align-items-center align-self-start">
                          <h4 className="mb-0 text-white">Verificar usuário</h4>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="icon icon-box-success">
                          <span className="mdi mdi-arrow-right icon-item"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </Grid>
            )}
            {user && user.status !== "ACTIVE" && (
              <Grid xs={6} md={3}>
                <Link
                  to="#"
                  className="card card-button"
                  onClick={() => setShowActivateUser(true)}
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="col-9">
                        <div className="d-flex align-items-center align-self-start">
                          <h4 className="mb-0 text-white">Ativar usuário</h4>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="icon icon-box-success">
                          <span className="mdi mdi-arrow-right icon-item"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </Grid>
            )}
            <Grid xs={6} md={3}>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-9">
                      <div className="d-flex align-items-center align-self-start">
                        <h4 className="mb-0">
                          {"$ " + numeral(userStatistics.accept).format("0,0.00")}
                        </h4>
                      </div>
                    </div>
                    {/* <div className="col-3">
                      <div className="icon icon-box-danger">
                        <span className="mdi mdi-arrow-bottom-left icon-item"></span>
                      </div>
                    </div> */}
                  </div>
                  <h6 className="text-muted font-weight-normal">Volume total</h6>
                </div>
              </div>
            </Grid>
            <Grid xs={6} md={3}>
              <Link
                to="#"
                className="card card-button"
                onClick={() => setShowLimitUser(true)}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-9">
                      <div className="d-flex align-items-center align-self-start">
                        <h4 className="mb-0 text-white">Limite de liquidez</h4>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="icon icon-box-success">
                        <span className="mdi mdi-arrow-right icon-item"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Grid>
            <Grid item xs={6} md={3}>
              <Link to="#" className="card card-button" onClick={handleLoginUser}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-9">
                      <div className="d-flex align-items-center align-self-start">
                        <h4 className="mb-0 text-white">
                          Entrar na Conta do Cliente
                        </h4>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="icon icon-box-success">
                        <span className="mdi mdi-arrow-right icon-item"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Grid>
            <Grid item xs={6} md={3}>
            <Link to="#" className="card card-button" onClick={handleLoginAdmin}>
              <div className="card-body">
                <div className="row">
                  <div className="col-9">
                    <div className="d-flex align-items-center align-self-start">
                      <h4 className="mb-0 text-white">
                        Entrar na Conta do Admin
                      </h4>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="icon icon-box-success">
                      <span className="mdi mdi-arrow-right icon-item"></span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            </Grid>
            <Grid item xs={6} md={3}>
              <Link
                to="#"
                className="card card-button"
                onClick={() => setShowAddAffiliate(true)}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-9">
                      <div className="d-flex align-items-center align-self-start">
                        <h4 className="mb-0 text-white">
                          Adicionar afiliado
                        </h4>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="icon icon-box-success">
                        <span className="mdi mdi-arrow-right icon-item"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Grid>
            <Grid item xs={6} md={3}>
              <Link to="#" className="card card-button" onClick={handleRemoveAffiliate}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-9">
                      <div className="d-flex align-items-center align-self-start">
                        <h4 className="mb-0 text-white">
                          Remover afiliado
                        </h4>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="icon icon-box-success">
                        <span className="mdi mdi-arrow-right icon-item"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Grid>
            {userStatistics.userData && userStatistics.userData.using2fa !== undefined && (
              <Grid item xs={6} md={3}>
                <Link to="#" className="card card-button" onClick={handleToggle2fa}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-9">
                        <div className="d-flex align-items-center align-self-start">
                          <h4
                            className="mb-0 text-white">
                            {userStatistics?.userData?.using2fa ? 'Desabilitar 2FA' : 'Habilitar 2FA'}
                          </h4>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="icon icon-box-success">
                          <span className="mdi mdi-arrow-right icon-item"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </Grid>
            )}
          </>
        )}

        <Grid item xs={12} m={0} p={0}>
          <Grid container>
            <Grid xs={4}>
              <div className="col-xl-12 mb-2 grid-margin stretch-card">
                <h6>Últimos depósitos</h6>
              </div>
              <div className="card">
                <div className="card-body">
                  <div
                    className="table-responsive"
                    style={{ maxHeight: "250px", overflowY: "scroll" }}
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Data</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userStatistics?.last10Deposits &&
                          userStatistics?.last10Deposits.map((item) => (
                            <tr key={item.time}>
                              <td>
                                {format(
                                  new Date(item.time),
                                  "dd/MM/yyyy HH:mm"
                                )}
                              </td>
                              <td>
                                <Chip
                                  className="win"
                                  value={numeral(item.value).format("0,0.00")}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid xs={4}>
              <div className="col-xl-12 mb-2 grid-margin stretch-card">
                <h6>últimos saques</h6>
              </div>
              <div className="card">
                <div className="card-body">
                  <div
                    className="table-responsive"
                    style={{ maxHeight: "250px", overflowY: "scroll" }}
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Data</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userStatistics?.last10Withdrawals &&
                          userStatistics?.last10Withdrawals.map((item) => (
                            <tr key={item.time}>
                              <td>
                                {format(
                                  new Date(item.time),
                                  "dd/MM/yyyy HH:mm"
                                )}
                              </td>
                              <td>
                                <Chip
                                  className="win"
                                  value={numeral(item.value).format("0,0.00")}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid xs={4}>
              <div className="col-xl-12 mb-2 grid-margin stretch-card">
                <h6>Últimos bônus</h6>
              </div>
              <div className="card">
                <div className="card-body">
                  <div
                    className="table-responsive"
                    style={{ maxHeight: "250px", overflowY: "scroll" }}
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Data</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userStatistics?.addedCredits &&
                          userStatistics?.addedCredits.map((item) => (
                            <tr key={item.time}>
                              <td>
                                {format(
                                  new Date(item.time),
                                  "dd/MM/yyyy HH:mm"
                                )}
                              </td>
                              <td>
                                <Chip
                                  className="win"
                                  value={numeral(item.value).format("0,0.00")}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Grid>
            {!ReportView && userStatistics?.deviceMetadata && (
              <Grid xs={12}>
                <TableMetadata
                  rows={userStatistics?.deviceMetadata}
                  loading={false}
                  stickyHeader
                />
              </Grid>
            )}
            {userStatistics?.hasAffiliates && (
              <>
                <Grid xs={12}>
                  <div className="col-xl-12 mt-2">
                    <h6>Afiliado</h6>
                  </div>
                </Grid>

                <Grid xs={4}>
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-9">
                          <div className="d-flex align-items-center align-self-start">
                            <h4 className="mb-0">
                              {userStatistics?.numberOfAffiliatesWhoHasDepositedOnce ||
                                0}
                            </h4>
                          </div>
                        </div>
                        {/* <div className="col-3">
                  <div className="icon icon-box-success ">
                    <span className="mdi mdi-arrow-top-right icon-item"></span>
                  </div>
                </div> */}
                      </div>
                      <h6 className="text-muted font-weight-normal">
                        Número de afiliados que depositaram
                      </h6>
                    </div>
                  </div>
                </Grid>
                <Grid xs={4}>
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-9">
                          <div className="d-flex align-items-center align-self-start">
                            <h4 className="mb-0">
                              {numeral(
                                userStatistics?.affiliatesOperatedVolume * 0.025
                              ).format("0,0.00")}
                            </h4>
                          </div>
                        </div>
                        {/* <div className="col-3">
                  <div className="icon icon-box-success ">
                    <span className="mdi mdi-arrow-top-right icon-item"></span>
                  </div>
                </div> */}
                      </div>
                      <h6 className="text-muted font-weight-normal">
                        Comissão do afiliado
                      </h6>
                    </div>
                  </div>
                </Grid>
                <Grid xs={4}>
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-9">
                          <div className="d-flex align-items-center align-self-start">
                            <h4 className="mb-0">
                              {userStatistics?.affiliatesOperatedVolume}
                            </h4>
                          </div>
                        </div>
                        {/* <div className="col-3">
                  <div className="icon icon-box-success ">
                    <span className="mdi mdi-arrow-top-right icon-item"></span>
                  </div>
                </div> */}
                      </div>
                      <h6 className="text-muted font-weight-normal">
                        Volume Operado dos afiliados
                      </h6>
                    </div>
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Modal
        show={showVerifyUser}
        onHide={() => setShowVerifyUser(false)}
        hideBackdrop
      >
        <Modal.Header closeButton>
          <Modal.Title>Verificação de usuário manual</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Box>
            <Typography>
              Por favor, esteja ciente de que uma vez realizada a verificação
              manual, não será possível desfazer ou desfazer as alterações
              feitas. Se você estiver certo e desejar continuar com a
              verificação manual do usuário, clique em "verificar".
            </Typography>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button
            sx={{ mr: 1 }}
            variant="contained"
            onClick={() => setShowVerifyUser(false)}
          >
            Cancelar
          </Button>
          <Button variant="contained" onClick={() => handleVarifyUser()}>
            Verificar
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalAddCreditDebit
        open={showAddCreditDebit}
        onClose={toggleCloseModalAddCreditDebit}
        onConfirm={handleAddCreditDebit}
      />
      <ModalUpdateLiquidityUser
        open={showLimitUser}
        onConfirm={handleUpdateLiquidityUser}
        onClose={() => setShowLimitUser(false)}
      />
      <ModalManualActivateUser
        open={showActivateUser}
        onConfirm={handleActivateUser}
        onClose={() => setShowActivateUser(false)}
      />
      <ModalAddAffiliate
        open={showAddAffiliate}
        onClose={toggleCloseModalAddAffiliate}
        onConfirm={handleAddAffiliate}
      />
      <Snackbar
        open={openSnackbar.open ? true : false}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(InitSnackbar)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClick={() => setOpenSnackbar(InitSnackbar)}
          severity={openSnackbar.severity === "success" ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {openSnackbar.text}
        </Alert>
      </Snackbar>
    </>
  );
}
